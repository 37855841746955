<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card-text
            style="text-align: center;"
            class="mt-2"
          >
            Selecione o jogo.
          </b-card-text>
              <!-- se tiver mais de um jogo, colocar justify-content-between -->
          <div class="flex-container row d-flex justify-content-center ">
            <div
              class="column col-lg-5 mb-2 mb-lg-0 mb-sm-2"
              @click="linkConcentracao"
            >
              <p class="mt-1">
                Atenção
              </p>
              <b-img
                class="mt-2 mb-2"
                center
                :src="require('@/views/game/imagens/concentracao.png')"
              />
              <p
                v-if="jogoAtencao == 1"
                class="mt-3"
              >
                Falta <code>{{ jogoAtencao }}</code> jogada
              </p>
              <p
                v-if="jogoAtencao != 1"
                class="mt-3"
              >
                Faltam <code>{{ jogoAtencao }}</code> jogadas
              </p>
            </div>
            <!-- <div
              class="column col-lg-5"
              @click="linkMemoria"
            >
              <p class="mt-1">
                Memória
              </p>
              <b-img
                class="mt-2 mb-2"
                center
                :src="require('@/views/game/imagens/memoria.png')"
              />
              <p
                v-if="jogoMemoria == 1"
                class="mt-1"
              >
                Falta <code>{{ jogoMemoria }}</code> jogada
              </p>
              <p
                v-if="jogoMemoria != 1"
                class="mt-1"
              >
                Faltam <code>{{ jogoMemoria }}</code> jogadas
              </p>
            </div> -->
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="mediaJogadasModal"
      :jogo="jogo"
      class="col-12"
      title="Declaro:"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      centered
      ok-title="De acordo"
      @ok="TermosMediaJogada"
      @hidden="fecharModal"
    >
      <ul class="list-style-icons">
        <li>
          <feather-icon icon="ArrowRightIcon" />1. Estar com boa disposição
          física: descansado, alimentado e não ter ingerido bebida alcoólica nas
          últimas 24 horas.
        </li>
        <li>
          <feather-icon icon="ArrowRightIcon" />2. Estar com meus óculos ou
          lentes corretivas, caso tenha necessidade.
        </li>
        <li>
          <feather-icon icon="ArrowRightIcon" />3. Ter ciência de que não devo
          ser interrompido durante as jogas.
        </li>
      </ul>
    </b-modal>
    <b-modal
      ref="modalPosteriorJogadas"
      :jogo="jogo"
      class="col-12"
      title="Declaro:"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      centered
      ok-title="De acordo"
      @ok="TermosMediaJogada"
      @hidden="fecharModal"
    >
      <ul class="list-style-icons">
        <li>
          <feather-icon icon="ArrowRightIcon" />1. Estar com meus óculos ou
          lentes corretivas, caso tenha necessidade.
        </li>
        <li>
          <feather-icon icon="ArrowRightIcon" />2. Ter ciência de que não devo
          ser interrompido durante as jogadas.
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BLink,
  BImg,
  BModal
  // BButton,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import useJwt from "@/auth/jwt/useJwt"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    // BLink,
    BImg,
    BModal
    // BButton,
  },
  directives: {
    Ripple
  },
  data() {
    return {
      // userData: getUserData(),
      carregando: false,
      dataTermos: null,
      quantidadeJogadasAtencao: 5,
      quantidadeJogadasMemoria: 4,
      jogadas: 0,
      jogo: null,
      idDoPerfil: null,
      primeiroLogin: false,
      jogoMemoria: null,
      jogoAtencao: null,
      quantidadeTodosJogos: {
        atencao: {
          quantidadeJogos: null,
          parametroJogos: null
        },
        memoria: {
          quantidadeJogos: null,
          parametroJogos: null
        }
      }
    }
  },
  mounted() {
    this.idDoPerfil = getUserData().idDoPerfil
    this.getquantidadeJogos()
  },
  methods: {
    mostrarData: function() {
      // return new Date().toLocaleDateString()
      return new Date().toLocaleString()
    },
    fecharModal() {
      this.logout()
    },
    linkConcentracao() {
      this.jogo = "atencao"
      if (
        this.quantidadeTodosJogos.atencao.quantidadeJogos <
          this.quantidadeTodosJogos.atencao.parametroJogos &&
        this.idDoPerfil > 2
      ) {
        this.$refs.mediaJogadasModal.show()
      } else if (
        this.quantidadeTodosJogos.atencao.quantidadeJogos >=
          this.quantidadeTodosJogos.atencao.parametroJogos &&
        this.idDoPerfil > 2
      ) {
        this.$refs.modalPosteriorJogadas.show()
      } else {
        this.$router.push({ name: "preconcentracao" })
      }
    },
    linkMemoria() {
      this.jogo = "memoria"
      if (
        this.quantidadeTodosJogos.memoria.quantidadeJogos <
          this.quantidadeTodosJogos.memoria.parametroJogos &&
        this.idDoPerfil > 2
      ) {
        this.$refs.mediaJogadasModal.show()
      } else if (
        this.quantidadeTodosJogos.memoria.quantidadeJogos >=
          this.quantidadeTodosJogos.memoria.parametroJogos &&
        this.idDoPerfil > 2
      ) {
        this.$refs.modalPosteriorJogadas.show()
      } else {
        this.$router.push({ name: "prememoria" })
      }
    },

    async getquantidadeJogos() {
      const resp = await useJwt
        .quantidadeJogos()
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemErro("danger", "top-center")
        })
      if (resp.sucesso) {
        this.quantidadeTodosJogos = resp.dados
        this.jogoAtencao =
          this.quantidadeTodosJogos.atencao.parametroJogos -
          this.quantidadeTodosJogos.atencao.quantidadeJogos
        if (this.jogoAtencao <= 0) {
          this.jogoAtencao = 0
        }
        this.jogoMemoria =
          this.quantidadeTodosJogos.memoria.parametroJogos -
          this.quantidadeTodosJogos.memoria.quantidadeJogos
        if (this.jogoMemoria <= 0) {
          this.jogoMemoria = 0
        }
      }
    },
    TermosMediaJogada() {
      if (this.jogo === "atencao") {
        this.$router.push({ name: "preconcentracao" })
      } else {
        this.$router.push({ name: "prememoria" })
      }
    },

    logout() {
      if (this.primeiroLogin == true) {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem("userData")
        // this.$ability.update(initialAbility)
        this.$router.push({ name: "auth-login" }).catch(() => {})
      }
    }
  }
}
</script>
<style scoped>
.flex-container {
  width: 70%;
  /* min-height: 20%; */
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
}
.flex-container .column {
  padding: 10px;
  /*-webkit-flex: 1; /* Safari */
  /*-ms-flex: 1; /* IE 10 */
  /*flex: 1; /* Standard syntax */
  border: 1px solid rgb(158, 158, 158);
  border-radius: 10%;
  text-align: center;
}
.flex-container .column.bg-alt {
  margin-left: 20%;
}

.flex-container .column:hover {
  border-color: #0078d4;
  box-shadow: 0px 5px 10px #969696;
  -webkit-box-shadow: 0px 5px 10px #969696;
  color: #000;
}
</style>
